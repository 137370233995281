.header {
    border-bottom: 1px solid #dee2e6;
    font-family: "Play", sans-serif;
    padding: 5px 10px;
}

.navbar-brand {
    font-weight: bold;
}

.navbar-nav .nav-link {
    font-weight: bold;
}

.navbar-nav .nav-link:hover {
    color: #007bff;
}

.navbar-nav .nav-item.dropdown .dropdown-menu {
    background-color: #f8f9fa;
}

.navbar-nav .nav-item.dropdown .dropdown-menu .dropdown-item {
    font-weight: bold;
}

.navbar-nav .nav-item.dropdown .dropdown-menu .dropdown-item:hover {
    background-color: #e9ecef;
}

.navbar-light .navbar-brand {
    color: #007bff;
}

.navbar-light .navbar-nav .nav-link {
    color: #000;
}

.img-fluid {
    max-width: 100%;
    object-fit: cover;
}

@media screen and (max-width: 768px) {
    .img-fluid {
        transform: translateY(20px);
    }
}

.header-container {
    position: relative;
}

.navbar {
    z-index: 1;
    position: fixed;
    width: 100%;
}

.footer {
    background-color: #F8F9FA;
    position: fixed;
    bottom: 0;
    z-index: 1;
    border: 0px;
    box-shadow: 2px 0px 1px 0px #000;
    padding: 10px 0px;
}

.header-image-container {
    position: sticky;
    height: 100vh;
    margin-bottom: -70vh;
    z-index: -2;
}

.header-image-container img {
    width: 100%;
    height: 100%;
    z-index: -1;
}