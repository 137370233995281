.pageWrapper {
    background: #f2f2f2;
    border-radius: 5px 5px 0px 0px;
    width: calc(100% - 20px);
    margin: auto;
    margin-bottom: 40px;
    min-height: calc(75vh + 5px);
    padding: 20px;
}

@media screen and (max-width: 768px) {
    .pageWrapper {
        border-radius: 5px;
        width: calc(100%);
    }
}